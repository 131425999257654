import styles from './contactForm.module.scss';

import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

export function ContactForm() {
  return (
    <Formik onSubmit={handleSubmit} validationSchema={formSchema} initialValues={initialValues}>
      {({ isSubmiting }) => (
        <Form className={styles.form}>
          <div className={styles.formRow}>
            <div className={styles.formField}>
              <Field type="text" name="name" placeholder="Nombre" />
              <ErrorMessage name="name" component="strong" />
            </div>
            <div className={styles.formField}>
              <Field type="text" name="phone" placeholder="Teléfono" />
              <ErrorMessage name="phone" component="strong" />
            </div>
            <div className={styles.formField}>
              <Field type="email" name="email" placeholder="Email" />
              <ErrorMessage name="email" component="strong" />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formField}>
              <Field component="textarea" name="comment" placeholder="Consulta" />
              <ErrorMessage name="comment" component="strong" />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formField}>
              <button type="submit" disabled={isSubmiting}>
                Enviar
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

const initialValues = {
  name: '',
  phone: '',
  email: '',
  comment: '',
};

const formSchema = Yup.object().shape({
  name: Yup.string()
    .min(3)
    .max(100)
    .required(),
  phone: Yup.string()
    .min(3)
    .max(50),
  email: Yup.string()
    .email()
    .required(),
  comment: Yup.string().required(),
});

function handleSubmit(values, actions) {
  console.log(values);
}
