import React from 'react';

import { Layout } from '../components/layout';
import { SEO } from '../components/seo';

export default function() {
  const title = '10 razones para elegirnos.';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>
      <ul>
        <li>Responsabilidad y compromiso con los pacientes.</li>
        <li>Equipamiento e instrumental de última generación.</li>
        <li>Óptima relación entre costos y calidad del servicio.</li>
        <li>Uso de materiales tanto importados como nacionales de primera calidad.</li>
        <li>Aplicación de los más altos estándares de bioseguridad.</li>
        <li>Quirófanos y consultorios inhibidos de agentes infecciosos.</li>
        <li>Profesionales especializados y altamente capacitados.</li>
        <li>Laboratorio dental con técnicos altamente capacitados.</li>
        <li>Confortables sala de espera y sala pre-quirúrgica de lectura y relax.</li>
        <li>Amplia disponibilidad de turnos y horarios semanales.</li>
      </ul>
    </Layout>
  );
}
