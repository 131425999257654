import React from 'react';
import { Link } from 'gatsby';

import logoSrc from '../images/logo.png';
import styles from './header.module.scss';

export function Header({ siteTitle }) {
  return (
    <header className="header">
      <div className="layout">
        <nav className={styles.nav}>
          <Link to="/"><img src={logoSrc} alt={siteTitle} /></Link>
        </nav>
        <address className={styles.address}>
          Sede Villa Urquiza Belgrano
          <br />
          Teléfono: ( 54 11 ) 4524-1911 - 4521-1111
          <br />
          Dirección: Bauness 2584, CABA
        </address>
      </div>
    </header>
    );
  }
