/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { Header } from './header';
import { MainNav } from './mainNav';
import { ContactForm } from './contactForm';

export function Layout({ children }) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Header siteTitle={data.site.siteMetadata.title} />
          <div className="layout">
            <MainNav />
            <main>{children}</main>
            <footer>
              <ContactForm />
            </footer>
          </div>
        </>
      )}
    />
  );
}
