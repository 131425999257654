import React from 'react';
import { Link } from 'gatsby';

import styles from './mainNav.module.scss';

export function MainNav() {
  return (
    <nav className={`main-nav ${styles.host}`}>
      <Link activeClassName="active" to="/">
        Inicio
      </Link>
      <Link activeClassName="active" to="/especialidades/">
        Especialidades
      </Link>
      <Link activeClassName="active" to="/por-que-elegirnos/">
        Por qué elegirnos
      </Link>
      <Link activeClassName="active" to="/nuestro-centro/">
        Nuestro centro
      </Link>
    </nav>
  );
}
